
  .separateBlock {
    display: block;
    border: none;
    outline: none;
    background-color: var(--main-bg-color);
    height: 40px;
    width: 100%;
  }


@media screen and (min-width: 768px) {
  .separateBlock {
    
    height: 60px;
    
  }
}

@media screen and (min-width: 1280px) {
  .separateBlock {
      
      height: 120px;
     
    }
}