.bgMain {
  position: relative;
  height: 100vh;
  background-image: url('../../images/bgLoginMob.png');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.bgLoginTab {
  display: none;
}
.burger {
  position: absolute;
  top: 35px;
  right: 33px;
  width: 15px;
  cursor: pointer;
}

.logoRectangle {
  padding-top: 190px;
  margin: 0 auto;
  width: 60px;
}

.formWrapper {
  position: relative;

}

.error {
  position: absolute;
  bottom: -25px;
  left: 70px;
  color: #FFFFFF;
}

.loginForm {
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
    flex-direction: column;
    min-width: 240px;
    margin-right: 60px;
    margin-left: 60px;
    
}

.loginInput {
  background: #FFFFFF;
    border-radius: 20px;
    border: none;
    outline: none;
    padding: 6px 0 6px 20px;

}

.loginInput:not(:last-child) {
  margin-bottom: 16px;
}

.loginInput::placeholder {
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
  
  
    color: #DABC77;
}

.submit {

  background: #DABC77;
  border-radius: 20px;
  border: none;
  outline: none;
  padding: 6px 0 6px 0;
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
  
  
    color: #FFFFFF;
}

.mail {
  display: inline-block;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
    /* identical to box height */
  
  
    color: #DABC77;
  
 
  margin-left: 50%;
  transform: translateX(-50%);
  
}

@media screen and (min-width: 620px) {
  .bgMain {
    background-image: url('../../images/bgLoginTab.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    /* width: 100%; */
  }

    .loginWrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      /* width: calc(100vw/5); */
    }

        .error {
          left: 10px;
        }

    .logoRectangle {
      padding-top: 84px;
      width: 48px;
     
     
    }

    .loginForm {
      max-width: 225px;
      margin: 30px 0px 30px 0px;
      /* margin-top: 30px;
        margin-bottom: 30px; */
    }

    .mail {
      padding-bottom: 84px;
    }

        .loginInput {
          padding: 4px 0 4px 16px;
    
        }

    .loginInput::placeholder {
      font-weight: 500;
        font-size: 14px;
        line-height: 21px;
    }

    .loginInput:not(:last-child) {
  margin-bottom: 10px;
}


  .submit {
    font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      padding: 6px 0 6px 0;
  }
}

@media screen and (min-width: 1280px) {
  .bgMain {
    position: relative;
    height: 100vh;
  }
  .loginWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw/5);
  }
  .logoRectangle {
      
      width: 80px;
    }

    .loginForm {
     
      margin: 40px 0 40px 0;
      max-width: calc(100vw/5);
    }

        .loginInput {
          padding: 8px 0 8px 16px;
    
        }

        .loginInput::placeholder {
      font-weight: 600;
        font-size: 14px;
        line-height: 21px;
    }

        .submit {
    font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      padding: 6px 0 6px 0;
  }

        .mail {
          padding-bottom: 186px;
          font-weight: 500;
            font-size: 14px;
            line-height: 21px;
        }

       .loginInput:not(:last-child) {
                  margin-bottom: 18px;
                }

                 .burger {
          width: 25px;
        }
}