.mainInfoWrapper {
position: relative;
display: flex;
flex-direction: column;

}

.title {
  margin-top: 50px;
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;

  color: #dabc77;
}

.mainText {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;

  color: #011539;
}

.burger {
  position: absolute;
  top: 35px;
  right: 33px;
  width: 15px;
  cursor: pointer;
}

.mainList {
  margin-top: 50px;
  
}

.independentTabTextWrapper,
.secureTabTextWrapper,
.transparentTabTextWrapper,
.experiencedTabTextWrapper {
  display: none;
}

.secure {
  background-image: url('./../../images/secureMob.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 265px 101px 20px;
  color: white;
}

.independent {
  background-image: url('./../../images/independentMob.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 265px 101px 20px;
  color: white;
}

.transparent {
  background-image: url('./../../images/transparentMob.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 265px 101px 20px;
  color: white;
}

.experienced {
  background-image: url('./../../images/experiencedMob.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 265px 101px 20px;
  color: white;
}

.listItemTextWrapper {
  background-color: var(--main-bg-color);

  max-height: 100%;
  transition: max-height 15s;
}

.listItemTextTitle {
  padding-top: 50px;
  padding-left: 20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  letter-spacing: 0.03em;
  color: var(--main-color);
}

.listItemText {
  margin: 50px 20px 0px 20px;
  padding-bottom: 50px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: white;

}

.listItemTitle {
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.03em;
}



@media screen and (min-width: 768px) {

  .mainInfoWrapper {
      height: 100vh;
  
    }


        /* .titleInfoWrapper {
      height: calc(100vh / 2);
    } */


  .mainList {
    display: flex;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */
    flex-grow: 2;

    letter-spacing: 0.03em;
    
    min-height: calc(100vh - 366px);
  }


  .secure {
    background-image: url('./../../images/secureTab.png');
    width: calc(100vw / 4);
    padding: 20px 55px 269px 16px;
    
  }

  .independent {
    background-image: url('./../../images/independentTab.png');
    width: calc(100vw / 4);
    padding: 20px 55px 269px 16px;
  }

  .transparent {
    background-image: url('./../../images/transparendTab.png');
    width: calc(100vw / 4);
    padding: 20px 55px 269px 16px;
  }

  .experienced {
    background-image: url('./../../images/experiencedTab.png');
    width: calc(100vw / 4);
    padding: 20px 55px 269px 16px;
  }

  .listItemTextWrapper {
    display: none;
  }

  .independent:hover, .secure:hover, .transparent:hover, .experienced:hover {
    background-image: none;
    background-color: var(--main-bg-color);
    padding: 20px 15px 0px 16px;
    overflow-y: auto;
  }

  .independent:hover > .listItemTitle, .secure:hover > .listItemTitle,
  .transparent:hover > .listItemTitle,
  .experienced:hover> .listItemTitle {
    display: none;
  }

  .independent:hover > .independentTabTextWrapper,
  .secure:hover > .secureTabTextWrapper,
  .transparent:hover > .transparentTabTextWrapper,
  .experienced:hover > .experiencedTabTextWrapper {
    display: block;
    
  }

  .textTitleForTab {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    letter-spacing: 0.03em;

    color: #dabc77;
  }

  .textForTab {
    padding-top: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 250;
    font-size: 12px;
    line-height: 18px;
    color: white;
    letter-spacing: 0;
    overflow-y: auto;
  }
}

@media screen and (min-width: 1280px) {
  .title {
    margin-top: 50px;
    font-weight: 400;
    font-size: 28px;
    line-height: 42px;

    color: #dabc77;
  }

  .mainText {
    margin-top: 10px;
    margin-left: 50%;
    transform: translateX(-50%);
    font-weight: 400;
    font-size: 20px;
    line-height: 180%;
    width: 933px;
    color: #011539;
  }

  .mainList {
    margin-top: 60px;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    letter-spacing: 0.12em;
    /* min-height: calc(100vh - 426px); */
  
    
  }

  .listItemTitle {
    font-weight: 300;
      font-size: 24px;
      line-height: 36px;
      /* identical to box height */
    
      letter-spacing: 0.12em;
  }

  .secure {
    padding: 40px 108px 397px 30px;
  }

  .independent {
    padding: 40px 108px 397px 30px;
  }

  .transparent {
    padding: 40px 108px 397px 30px;
  }

  .experienced {
    padding: 40px 108px 397px 30px;
  }

  .independent:hover, .secure:hover,
  .transparent:hover,
  .experienced:hover {
    padding: 40px 26px 0px 30px;
  }

  .textTitleForTab {
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    letter-spacing: 0.12em;
  }

  .textForTab {
    padding-top: 24px;

    font-weight: 400;
    font-size: 16px;
    line-height: 200%;
  }

    .burger {
      width: 25px;
    }
}
